<script>
  import { params } from "@roxi/routify";
  import Locale from "../../locale/Locale.svelte";
  import { getThankYouMessage } from "../../utils";
  import ShopifyEmbed from "../../components/ShopifyEmbed.svelte";
  import { _, locale, init } from "svelte-i18n";

  const { orderID = null } = $params;
  let language = localStorage.getItem("enrol-app-locale");
  locale.set(language);
  init({
    fallbackLocale: "en",
    initialLocale: language,
  });
  let thank_you_message, price_value, firstName, lastName;
  $: {
    if (thank_you_message) {
      thank_you_message = thank_you_message
        .replaceAll("{orderID}", orderID)
        .replaceAll("{firstName}", firstName)
        .replaceAll("{lastName}", lastName);

      if (price_value) {
        thank_you_message = thank_you_message.replaceAll(
          "{price_value}",
          price_value || ""
        );
      }
    }
  }
  getThankYouMessage(orderID).then((res) => {
    if (res) {
      thank_you_message = res.price.variant.product.thank_you_message;
      firstName = res.first_name;
      lastName = res.last_name;
      price_value = res.price.value;
    }
  });
</script>

<Locale />
{#if thank_you_message}
  <div style="text-align: justify;">
    <br />
    {@html thank_you_message
      .replaceAll("{Shopify1}", "")
      .replaceAll("{shopify1}", "")}
    {#if thank_you_message.includes("{Shopify1}") || thank_you_message.includes("{shopify1}")}
      <h2>{$_("success.Add a Gwersi Storage Box to your order?")}</h2>
      <ShopifyEmbed />
    {/if}
  </div>
{:else}
  <h2>
    <strong>Success!</strong> - you are now enrolled for online learning on the Bardic
    course.
  </h2>

  <p>
    You will soon receive an email with full instructions including your
    username and a link to the online course login page.
    {#if orderID}
      If you do not receive an email, please check your spam folder and should
      you need to contact us, please note your Web Order No.
      <strong>{orderID}</strong>
    {:else}
      If you do not receive an email, please check your spam folder.
    {/if}
  </p>

  <p>
    If you also ordered the printed text version, this will be processed by our
    wonderful and efficient admin team at the office, and will be winging its
    way to you in the post as soon as possible.
  </p>

  <p>To enhance your learning journey, you may wish to checkout:</p>

  <ul>
    <li>
      <a href="https://store.druidry.org/" target="_blank">Online Store</a>
    </li>
    <li>
      <a href="https://www.facebook.com/druidry/" target="_blank"
        >Tea with a Druid live broadcast (every Mon 8pm UK time)</a
      >
    </li>
    <li>
      <a href="https://www.druidry.org/library" target="_blank"
        >The online library</a
      >
    </li>
    <li>
      Listen to the Order’s podcast <a
        href="https://druidcast.libsyn.com/"
        target="_blank">Druidcast</a
      >
    </li>
  </ul>

  <p>
    We are truly delighted to welcome you as a member of the Order of Bards,
    Ovates &amp; Druids, and we hope you enjoy following the course.
  </p>
  <p>Yours under the oaks,</p>
  <p><strong>All of us in the OBOD office</strong></p>
{/if}
<div class="btn-container">
  <a class="btn btn--primary" href={$_("success.back_to_website_url")}
    >{$_("success.Back to the OBOD website")}</a
  >
</div>

<style>
  p,
  ul {
    margin: 0.5em 0;
  }

  p {
    text-align: justify;
  }

  li {
    margin-left: 1em;
    font-style: italic;
  }
</style>
